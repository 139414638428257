.ant-upload-list {
  margin-top: 16px;

  &.ant-upload-list-picture {
    .ant-upload-list-item {
      border-radius: 0;
    }

    .ant-upload-list-item-name {
      font-weight: @font-weight-extraLight;
      color: @primary-1;
    }

    .ant-upload-list-item-error {
      border-color: @danger-2;

      .ant-upload-list-item-name {
        color: @danger-2;
      }

      button {
        .anticon-delete {
          svg {
            fill: @danger-2;
          }
        }
      }
    }
  }

  .ant-upload-list-item {
    .ant-upload-list-item-info {
      .ant-upload-span {
        .ant-upload-list-item-thumbnail {
          svg {
            width: 24px;
            height: 24px;

            path {
              &:nth-child(1) {
                fill: @b-100;
              }

              &:nth-child(2) {
                fill: @b-0;
                stroke: @b-100;
              }

              &:nth-child(3) {
                fill: @b-0;
                stroke: @b-100;
              }

              &:nth-child(5) {
                fill: @b-100;
              }
            }
          }
        }
      }
    }
  }
}

.ant-upload-list-picture-container {
  width: 100%;

  .ant-upload-list-item {
    .ant-upload-list-item-info {
      .ant-upload-span {
        .ant-upload-list-item-thumbnail {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.ant-upload.ant-upload-select-picture-card {
  background-color: @b-20;
  border-radius: 0;
}

.avatar-uploader {
  .ant-upload.ant-upload-select-picture-card {

    // border: 1px solid black;
    border: 1px dotted #d0caca;
    padding: 5px;
    object-fit: contain;
  }
}

.ant-upload-list-picture-card {
  .ant-upload-list-item {
    border-radius: 0;

    &:hover {
      .ant-upload-list-item-info {
        &::before {
          opacity: 0.8;
        }
      }
    }
  }
}

.ant-upload-list-picture-card {
  .ant-upload-list-item-error {
    border-color: @danger-2;
  }

  .ant-upload-list-item-info {
    &::before {
      background-color: @b-80;
    }
  }

  .ant-upload-list-item-actions {
    .ant-btn-icon-only {
      padding: 0;
      width: auto;
      margin-left: 10px;
      background: #fff0 !important;
    }

    .anticon {
      svg {
        fill: @b-0;
      }
    }
  }
}

.ant-upload-list-item-error {
  .ant-upload-list-item-name {
    color: @danger-2;
  }
}

.ant-upload.ant-upload-drag {
  background-color: @b-20;
  padding: 0 16px;
}

.@{class-prefix}-upload-dragger-col {
  span {
    display: block;
  }
}

@media @sm {
  .ant-upload-list {
    display: flex;
    flex-wrap: wrap;
    margin: 16px -4px 0;

    .ant-upload-list-picture-card-container {
      flex: 0 0 calc(50% - 8px);
      margin: 0 4px 8px;
    }

    .ant-upload.ant-upload-select-picture-card {
      flex: 0 0 calc(50% - 8px);
      margin: 0 4px 8px;
    }
  }
}