.profile-head{
    position: relative;
}

.profile-head .cover-photo{
    background: url(https://img.freepik.com/free-photo/blue-jeep-parking-public-zone_114579-4042.jpg?w=740&t=st=1662120643~exp=1662121243~hmac=9f7aa645d0983a05f3d35349dc303516c52999941ce13d0223a9b4edc12456c4);
    background-position: 50%;
    background-size: cover;
    min-height: 15.625rem;
    width: 100%;
}

.rounded {
    border-radius: 10px 10px 0px 0px;
}
.profile-info{ 
    display: flex;
}
.profile-photo{
    margin-top: -4.5rem;
    position: absolute;
    z-index: 1;
}
@media @xs{
    .@{class-prefix}-d-flex-full-center{
        justify-content: left;
    }
}

.@{class-prefix}-profile-activity{
    .avatar-uploader {
        .ant-upload.ant-upload-select-picture-card {
          border: 1px dotted #d0caca;
          padding: 5px;
          object-fit: contain;
          border-radius: 63px;
        }
      } 

      .ant-upload.ant-upload-select-picture-card {
        width: 120px !important;
        height: 120px;
        margin-right: 8px;
        margin-bottom: 8px;
        text-align: center;
        vertical-align: top;
        background-color: #fafafa;
        border: 1px dashed #b2bec3;
        border-radius: 7px;
        cursor: pointer;
        transition: border-color 0.3s;
    }

    .@{class-prefix}-preview-img {
        height: 108px;
        object-fit: contain;
        border-radius: 63px;
    }

    
}
