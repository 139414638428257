.ant-upload.ant-upload-select-picture-card {
    background-color: #ffffff;
    border-radius: 0;
    border: 0px;
}

.@{class-prefix}-upload-btn{
    background-color: #fafafa;
    padding: 5px 15px;
    color: #000;
    border: 1px solid #f0f0f0;
}

.ant-upload.ant-upload-select-picture-card > .ant-upload{
    display: block;
}

.ant-upload-list-picture-card-container {
    display: inline-block;
    width: 120px;
    height: 120px;
    margin: 0 8px 8px 0;
    vertical-align: top;
}

.@{class-prefix}-submit-btn{
    padding: 10px 45px;
    margin: 0 auto;
    display: block;
    font-size: 16px;
}

.ant-upload-list{
    @media @md{
        display: contents;
    }
}