@import "error/index.less";
@import "authentication/index.less";
@import "profile/index.less";
@import "purchase/index.less";
@import "vehicleCounterSales/index.less";
@import "stockInOut/index.less";
@import "transaction/index.less";
@import "settings/index.less";
@import "stock/index.less";
@import "managerUsers/index.less";
@import "visitCard/_visit-card.less";

.@{class-prefix}-mobile-none {
    @media @sm {
        display: none;
    }
}

.@{class-prefix}-isMobile {
    display: none;

    @media @sm {
        display: block;
    }
}

.@{class-prefix}-letter-space-1{
    letter-spacing: 1px;
}

.ant-table.ant-table-middle .ant-table-tbody > tr > td{
    padding: 7px 10px;
}

.ant-table.ant-table-middle .ant-table-thead > tr > th{
    padding: 10px 10px;
}   

.@{class-prefix}-text-color-white{
    color: #ffffff;
}
// .@{class-prefix}-text-color-white{
//     &:hover{
//         color:#1b2285;
//     }
// }