//
// print.scss
//

// Used invoice page
@media print {

  // .vertical-menu,
  // .right-bar,
  // .page-title-box,
  // .navbar-header,
  // .footer {
  //   display: none !important;
  // }
  // .card,
  // .card-body,
  // .main-content,
  // .right-bar,
  // .page-content,
  body {
    padding: 0;
    margin: 0;
  }

  @page {
    size: auto;
    /* auto is the initial value */
    margin: 0;
    width: 100%;
  }

  #toPdf {
    width: auto !important;
  }

  .no-print {
    display: none;
  }

  .printme {
    display: block;
  }

  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }

  .hp-text-center {
    text-align: center; /* Center-align the content */
  }

  .print-content {
    text-align: center; /* Center-align the content when printing */
    margin: 0 auto; /* Center horizontally on the printed page */
    page-break-after: always; /* Add page break after each content block */
  }
  
}


