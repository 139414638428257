.@{class-prefix}-accept-btn {
    background-color: #10b759;
    color: #fff;
    min-height: 35px;
    letter-spacing: 1;
}

.@{class-prefix}-reject-btn {
    background-color: #bd2130;
    color: #fff;
    min-height: 35px;
}